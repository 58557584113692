<template>
<header class="container-fluid" v-cloak id="websiteHeader">
    <Modal
        :mail="mail"
        :name="name"
    />
    <div class="col-12 row">
        <div class="col-lg-2 d-none d-lg-block"></div>
        <div class="col">
            <ul>
                <!-- xp calc -->
                <li v-if="loggedIn" class="p-0">
                    <span
                        class="fa-stack cursor-help"
                        v-tippy
                        :content="`Level: ${xpCalc.level} | XP: ${xp} | Next level: ${xpCalc.toNext}`"
                    >
                        <i class="fa-stack-1x fas fa-address-card c-blue" style="opacity: .7"></i>
                        <i class="fas fa-circle-notch c-red notification-circle">
                            <div class="notification-number fw-bold">{{xpCalc.level}}</div>
                        </i>
                        <div class="progress level-progress">
                            <div class="progress-bar bg-xp" :style="`width: ${xpCalc.width[0]}; opacity: .7`"></div>
                            <div class="progress-bar bg-danger" :style="`width: ${xpCalc.width[1]}`"></div>
                            <div class="progress-bar bg-background" :style="`width: ${xpCalc.width[2]}`"></div>
                        </div>
                    </span>
                </li>
                <!-- light/dark toggle -->
                <li class="p-0 highlightable" @click="swapColorTheme">
                    <span class="fa-stack">
                        <i
                            id="theme_toggle"
                            class="fas clickable fa-stack-1x"
                            :class="darkMode ? 'fa-moon' : 'fa-sun'"
                            content="Toggle light/dark mode"
                            v-tippy
                        ></i>
                    </span>
                </li>
                <!-- home link -->
                <li class="d-md-none">
                    <router-link to="/">
                        <span class="fa-stack">
                            <i class="fas fa-home fa-stack-1x" v-tippy content="Home"></i>
                        </span>
                    </router-link>
                </li>
                <!-- login -->
                <li v-if="!name">
                    <span class="fa-stack" @click="openModal()">
                        <i class="fas fa-user fa-stack-1x" v-tippy content="Login or create account"></i>
                    </span>
                </li>
                <!-- reader link -->
                <li class="p-0">
                    <router-link to="reader"
                    :active="this.$route.name === 'Reader'"
                    class="highlightable"
                    >
                        <span class="fa-stack">
                            <i
                                id="theme_toggle"
                                class="fas fa-clickable fa-stack-1x fa-book-open"
                                content="Go to Reader"
                                v-tippy
                            ></i>
                        </span>
                    </router-link>
                </li>
                <!-- break if on mobile -->
                <br class="d-block d-md-none">
                <li @mousedown="preloadApiBooks()">
                    <router-link to="/books"
                        v-tippy
                        content="Find a book/anime to learn from"
                        class="highlightable"
                    >library</router-link>
                </li>
                <li v-if="loggedIn">
                    <router-link to="/list"
                        v-tippy
                        content="Find words to learn"
                        class="highlightable"
                    >learn</router-link>
                </li>
                <li v-if="loggedIn">
                    <router-link to="/lessons"
                        v-tippy
                        content="Learn words & manage your SRS"
                        class="highlightable"
                    >manage</router-link>
                </li>
                <li v-if="loggedIn">
                    <router-link to="/review"
                        :active="typeof reviews == 'number' && reviews >= 1"
                        :event="typeof reviews == 'number' && reviews >= 1 ? 'click' : ''"
                        :disabled="!reviews || reviews === 0"
                        v-tippy
                        :content="`Practice words that are ready to be reviewed${reviews && reviews > 0 ? '<br />'+reviews+' ready' : '<br />No words to review right now'}`"
                        class="highlightable"
                    >
                        practice
                    </router-link>
                </li>
                <!-- dropdown menu -->
                <li v-show="loggedIn"
                    class="nav-item"
                    @mouseenter="openDropdown()"
                    @mouseleave="closeDropdown()"
                >
                    <div class="dropdown">
                        <i class="fas fa-angle-down" id="more-links" href="#" data-bs-toggle="dropdown"></i>
                        <ul class="dropdown-menu" aria-labelledby="more-links">
                            <li><router-link class="dropdown-item" to="/data">user statistics<i class="fas fa-poll"></i></router-link></li>
                            <li>
                                <a @click="openModal" class="dropdown-item" >
                                    mail
                                    <span class="fa-stack">
                                        <i class="fas fa-envelope"></i>
                                        <i v-if="mail.find(i=>!i.opened)" class="fas fa-circle c-blue notification-circle">
                                            <div class="notification-number" style="color:white!important">{{mail.filter(i=>!i.opened).length}}</div>
                                        </i>
                                    </span>
                                </a>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li><a @click="openModal()" class="dropdown-item" >trimming<i class="fas fa-cut"></i></a></li>
                            <li><router-link class="dropdown-item" to="/exporter">export user data<i class="fas fa-file-download"></i></router-link></li>
                            <li><router-link class="dropdown-item" to="/importer">import known words<i class="fas fa-file-upload"></i></router-link></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><router-link class="dropdown-item" to="/welcome">features</router-link></li>
                            <li><router-link class="dropdown-item" to="/policy">policy</router-link></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><a class="dropdown-item" href="#" @click="runLogout()">logout<i class="fas fa-sign-out-alt"></i></a></li>
                            <li><a class="dropdown-item" href="https://community.wanikani.com/t/koohicafe-the-other-wk-friendly-srs/48703" target="_blank">discussion thread <i class="fas fa-external-link-square-alt"></i></a></li>
                            <li><a class="dropdown-item" href="https://ko-fi.com/W7W81N315" target="_blank">donate<i class="fas fa-donate"></i></a></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><a class="dropdown-item" href="https://discord.com/" target="_blank" style="font-variant: none">discord: raionus#8576</a></li>
                        </ul>
                    </div>
                </li>
                <!-- features (not logged in only) -->
                <li v-if="!loggedIn">
                    <router-link to="/welcome">
                        features
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="d-none d-md-block col-2 ml-auto align-right logo">
            <router-link class="fromLeft d-inline-block" to="/"><h2 class="fancy">Koohī.</h2></router-link>
        </div>
        <div class="col-lg-2 d-none d-lg-block"></div>
    </div>

    <div class="break" style="padding:0"></div>
</header>
</template>

<script>
/* eslint-disable no-mixed-operators */
import { Modal as Bmodal, Dropdown } from 'bootstrap/dist/js/bootstrap.esm.min'
import Vue from "vue";
import VueTippy, { TippyComponent } from "vue-tippy";
import { LS, SS } from '@/assets/constants'
import C from '@/assets/common'
import Modal from './Modal.vue'
import M from './methods'
import {
    preloadApiBooks,
    preloadLessons,
} from './preloads'

const {
    setColorTheme,
    SendUserAlert,
} = C
const {
    updateHeartbeat, invalidateHeartbeat, getHeartbeat, logout
} = M

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

export default {
name: 'Heading',
props: {
msg: String,
},
components: {
    Modal
},
data() {
return {
    modals: {
        login: null,
        settings: null,
        moreLinks: null,
    },
    loggedIn: false,
    name: "",
    reviews: 0,
    lessons: 0,
    xp: 0,
    xpToday: 0,
    composition: "",
    mail: [
    ],
    token: null,
    darkMode: false,
}
},
computed: {
    localStorage() {
        return window.localStorage
    },
    xpCalc() {
        const perLevel = 750;
        const level = Math.floor(this.xp / perLevel)
        const toNext = perLevel - (this.xp % perLevel)
        const today = (this.xpToday % perLevel)
        const todayWidth = () => {
            const prog = (this.xp % perLevel) - this.xpToday
            const progForCalc = prog > 0 ? prog : 0
            const xpTodayForCalc = this.xpToday > (this.xp % perLevel) ? (this.xp % perLevel) : this.xpToday
            return [`${progForCalc / perLevel * 100}%`, `${(xpTodayForCalc) / perLevel * 100}%`, `${(toNext / perLevel * 100)}%`]
        }
        return {
            level,
            toNext,
            today,
            width: todayWidth(),
            perLevel
        }
    }
},
methods: {
    updateHeartbeat,
    invalidateHeartbeat,
    getHeartbeat,
    openModal() {
        this.name ? this.modals.settings.show() : this.modals.login.show()
    },
    openDropdown() {
        this.modals.moreLinks.show()
    },
    closeDropdown() {
        this.modals.moreLinks.hide()
    },
    runLogout() {
        logout()
        .then((res) => {
            this.invalidateHeartbeat()
            this.name = ""
            this.loggedIn = false
            this.reviews = 0
            this.lessons = 0
            this.mail = []
        })
        .then(() => {
            document.location.href = '/'
        })
    },
    preloadApiBooks,
    preloadLessons,
    swapColorTheme() {
        this.darkMode = !this.darkMode
        if (this.darkMode) {
            // now in dark mode
            LS.set(LS.DARK, true)
        } else {
            LS.remove(LS.DARK)
        }
        setColorTheme()
        setColorTheme(document.getElementById('site-img'))
    }
},
created() {
    console.log('%cCreated Heading component', window.ConsoleStyles.createdComponent, this)
    //  set document dark mode
    setColorTheme()
    /* setColorTheme('#site-img') */
    setColorTheme(document.getElementById('site-img'))
    if (LS.get(LS.DARK)) this.darkMode = true
    //  update heartbeat, check for errors
    try {
        // check if logged in
        const lastHeartbeat = SS.get(SS.LAST_HEARTBEAT)
        const token = LS.get(LS.TOKEN)
        this.token = token

        // is first visit on site?
        if (!lastHeartbeat) {
            console.warn('using public api', lastHeartbeat)
            SS.set(SS.AWAITING_FIRST_HEARTBEAT, true)
            SS.set(SS.PUBLIC_API, true)
        }
        // has a token?
        if (this.token) {
            this.getHeartbeat()
            .then(this.updateHeartbeat)
            .then(() => {
                SS.remove(SS.PUBLIC_API)
            })
            .catch((res) => {
                // its probably invalid
                console.log(`err updating heartbeat${res}`)
                this.token = null
            })
            .then(() => {
                // allows components to safely fetch options
                SS.set(SS.AWAITING_FIRST_HEARTBEAT, false)
            })
        }
    } catch (e) {
        SendUserAlert('Error with login information. Resetting info.', 'alert-danger')
        console.warn('Error with login information. Info: ', e)
        this.invalidateHeartbeat()
    }
},
watch: {
    loggedIn: {
        immediate: true,
        handler(n) {
            if (n) {
                return this.$nextTick(() => {
                    const setupDropdowns = () => {
                        const node = document.getElementById('more-links')
                        if (!node) return
                        const x = new Dropdown(node)
                        this.modals.moreLinks = x
                    }
                    setupDropdowns()
                })
            }
            return this.$nextTick(() => {
                    const setupModals = () => {
                        const settings = new Bmodal(document.getElementById('settingsModal'), {})
                        this.modals.settings = settings
                        const login = new Bmodal(document.getElementById('loginModal'), {})
                        this.modals.login = login
                        if (!window.modals) {
                            window.modals = { login }
                        } else {
                            window.modals.login = login
                        }
                    }
                    setupModals()
                })
        }
    }
},
beforeRouteUpdate(to, from, next) {
    if (this.token) {
        this.getHeartbeat()
        .then(this.updateHeartbeat)
        .catch((res) => {
            // its probably invalid
            console.log(`err updating heartbeat${res}`)
            this.token = null
        })
    }
    return next()
}
}
</script>

<style scoped lang="sass">
body
    &.dark
        li
            i.fa-stack-1x
                color: rgba(255, 255, 255, 0.6)
        a[active="true"]
            text-decoration: none
            color: rgba(0, 171, 255, 0.76)!important
header
    margin: 0!important
    padding: 0!important
    a, a:hover, a:active, a:visited
        text-decoration: none
    .logo
        color: rgba(84,137,163,0.76)
        h2.standard
            font-family: 'Poppins', sans-serif
        h2.fancy
            font-family: 'Sacramento', sans-serif
            color: #00ce1b
            color: rgba(0, 171, 255, 0.76)
    ul
        margin: 0
        list-style-type: none
        > li
            display: inline-block
            font-variant: small-caps
            padding: .25em
            font-weight: 100
    h2
        font-weight: 200
        letter-spacing: .06em
        margin: 0
    .break
        border-bottom: 12px solid #1b1b1b
        box-shadow: 0 2px 5px #000000
        margin-bottom: 2rem
    .fa-stack
        .fa-stack-1x
            color: #212529
    .router-link-exact-active, .router-link-active, .router-link-active .fas
        color: rgba(0, 171, 255, 0.76)!important
    .highlightable:not([disabled])
        a:hover, .fas:hover
            color: rgba(0, 171, 255, 0.76)
        &:after
            display: block
            content: ''
            border-bottom: solid 3px rgba(0, 171, 255, 0.76)
            transform: scaleX(0)
            transition: transform 200ms ease-out
        &.fromRight:after
            transform-origin: 100% 50%
        &.fromLeft:after
            transform-origin:  0% 50%
        &:hover
            text-decoration: none
            color: rgba(0, 171, 255, 0.76)!important
        &:hover:after, a[active="true"]:after
            transform: scaleX(1)
    a[active="true"]
        text-decoration: none
        color: rgba(0, 171, 255, 0.76)!important
    a[disabled]
            opacity: .5
    #mail-system
        .fas.fa-times
            color: white
    .dropdown-menu.show
        font-size: 1.15rem
        background: #121212
        li
            display: block
            a
                color: white
            &:hover
                a, a i
                    color: #212529
                background-color: #f8f9fa
        li
            i
                padding: .5em .5em
    .fa-stack
        .notification-circle
            z-index: 2
            top: 33%
    .level-progress
        position: absolute
        transform: translate(-50%, -50%)
        left: 50%
        top: 85%
        width: 75%
        height: 4px
        background-color: transparent
        .bg-xp
            background-color: #0082ce !important
        .bg-background
            background-color: #cacaca!important
</style>
