class ConsoleStyles {
    /*
    * will not return apiCallResult unless localStorage has a 'debug_api' set
    */
    static createdComponent = [
        "color: green",
        "border: 2px dotted green",
        "border-radius: 4px",
        "padding: 0 .5em",
        "display: inline"
    ].join(';')

    static originComponent = [
        "color: green",
        "border: 2px solid green",
        "border-radius: 4px",
        "padding: 0 .5em",
        "display: block",
        "font-weight: bold",
    ].join(';')

    static debug = [
        "color: grey"
    ].join(";")

    //  routine, automatic
    static routine = [
        "color: grey",
        "border: 2px dotted grey",
        "border-radius: 4px",
        "padding: 0 .5em",
        "display: inline"
    ].join(";")

    //  routine, consequence of user action
    static routineConsequence = [
        "color: blue",
        "border: 2px dotted blue",
        "border-radius: 4px",
        "padding: 0 .5em",
        "display: inline"
    ].join(";")

    //  api call (requires using the getter)
    static #apiCall = [
        "color: orange",
        "border: 2px dotted orange",
        "border-radius: 4px",
        "padding: 0 .5em",
        "display: inline"
    ].join(";")

    static apiCallResult(msg, result) {
        if (!window.localStorage.getItem('debug_api')) return
        const messages = Array.isArray(msg) ? msg.join(' | ') : msg
        const output = [`%cAPI ${messages}`, ConsoleStyles.#apiCall]
        // eslint-disable-next-line consistent-return
        return console.log(...output, result)
    }

    static info = [
        "color: rgb(82, 111, 255)",
        "border: 2px dotted grey",
        "border-radius: 4px",
        "padding: 0 .5em",
        "display: inline"
    ].join(";")
}

export default ConsoleStyles
