import ApiPreloader from '@/assets/preloader'
import { UseAPI, HandleRequestFail } from '@/assets/common'

export const preloadApiBooks = () => {
    const key = "/books"
    const dataAlreadyLoaded = ApiPreloader.apiDataExists(key, true)
    if (dataAlreadyLoaded) return
    const expiration = 1000 * 60 * 60
    const apiOpts = { method: "POST" }
    if (!localStorage.getItem('token')) {
        apiOpts.method = "GET"
        apiOpts.auth = false
        /* this.flags.login = false */
    }
    const getBooks = () => UseAPI('/get/booksWithFlags', apiOpts)
                            .then((dat) => {
                                console.warn('Preloader resolved', key, 'correctly')
                                return Promise.resolve(dat)
                            })
                            .catch((dat) => {
                                console.warn('Preloader rejected', key, 'correctly')
                                /* HandleRequestFail(dat) */
                                return Promise.reject()
                            })
    ApiPreloader.queueApiCall({ key, func: getBooks, expiration }, true)
}

export const preloadLessons = () => {
    const key = "/lessons"
    const dataAlreadyLoaded = ApiPreloader.apiDataExists(key, true)
    if (dataAlreadyLoaded) return
    const expiration = 1000 * 60 * 1
    const apiOpts = { method: "POST" }
    const getLessons = () => UseAPI('/get/lessons', apiOpts)
                            .then((dat) => {
                                console.warn('Preloader resolved', key, 'correctly')
                                return Promise.resolve(dat)
                            })
                            .catch((dat) => {
                                console.warn('Preloader rejected', key, 'correctly')
                                /* HandleRequestFail(dat) */
                                return Promise.reject()
                            })
    ApiPreloader.queueApiCall({ key, func: getLessons, expiration }, true)
}

export default {
    preloadApiBooks
}
