<template>
<div class="mail-container">
    <div v-if="mail.length == 0"
        class="col-12 mail-row marg-big"
    >
        <div class="w-100 d-flex mail-header">
            <div class="w-75">
                <span class="mail-title">No messages</span>
            </div>
        </div>
    </div>
    <div class="col-11 mx-auto mail-row marg-big"
        v-for="(items, indx) in mail" :key="indx"
    >
        <div class="w-100 d-flex mail-header">
            <div class="w-75">
                <span class="mail-title">{{items.title}}</span>
                <span v-if="!items.opened"
                class="mail-sender badge bg-success"
                >new</span>
                <span class="mail-sender badge bg-purple">{{'from ' +items.sender}}</span>
            </div>
            <div class="w-25 align-right d-flex justify-content-end">
                    <span class="fa-stack clickable" @click="$emit('message-clicked', [items, items.id])">
                        <i class="fas fa-square fa-stack-2x" :class="flags.activeMsg === items.id ? 'c-purple' : 'c-jet'"></i>
                        <i class="fas fa-stack-1x fa-angle-down"></i>
                    </span>
                    <span v-if="!items.permanent"
                    class="fa-stack"
                    v-tippy
                    content="delete message"
                    @click="$emit('message-deleted', [items, indx])"
                    >
                        <i class="fas fa-square fa-stack-2x"></i>
                        <i class="fas fa-times fa-stack-1x"></i>
                    </span>
            </div>
        </div>
        <div class="w-75 marg-big" v-if="flags.activeMsg === items.id">
            {{items.content}}
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Mail',
    props: ['mail', 'flags'],
    created() {
        console.log('%cCreated Mail', window.ConsoleStyles.createdComponent, this)
    }
}
</script>

<style lang="sass" scoped>
body
    .mail-container
        max-height: 600px
        overflow-y: auto
    .mail-row
        background-color: #eee
        padding: .5em 1em
        border-radius: 4px
        i.fa-stack-2x
            color: #222
        i.fa-stack-1x
            color: white
    .mail-title
        margin-right: .5em
        vertical-align: top
    &.dark
        .mail-row
            background-color: #222
            i.fa-stack-2x
                color: #5d5d5d
</style>
