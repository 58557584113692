import Lockr from 'lockr'
import C from '@/assets/common'
import ApiPreloader from '@/assets/preloader'
import ConsoleStyles from '../../assets/consoleStyles'

const {
    UseAPI, HandleRequestFail, SendUserAlert, askForTransfer
} = C

export default {
    getTrimming() {
        const vm = this
        UseAPI('/get/trimming', { method: "POST" })
        .then((res) => {
            vm.trimming = res.modules.byLevel
        })
        .catch((dat) => {
            alert('error modifying data\ncheck console for logs')
            HandleRequestFail(dat)
            console.log('error log')
            console.log(JSON.stringify(dat))
        })
    },
    handleTrimming(typ = null) {
        const vm = this
        let endpoint = null
        switch (typ) {
            case 'lessons':
                endpoint = '/delete/trim-lessons'
                break
            case 'active':
                endpoint = '/delete/trim-active'
                break
            case 'active-low':
                endpoint = '/delete/trim-active-low'
                break
            default:
                endpoint = null
        }
        if (!endpoint) {
            SendUserAlert('Incorrect api setting', 'alert-danger')
            return false
        }
        return UseAPI(endpoint, { method: "DELETE" })
        .then((res) => {
            console.log(res)
            vm.getTrimming()
            SendUserAlert('Trimming completed. Updating interface...', 'alert-success')
        })
        .catch((dat) => {
            alert('error modifying data\ncheck console for logs')
            HandleRequestFail(dat)
            console.log('error log')
            console.log(JSON.stringify(dat))
        })
        .then(() => {
            //  clear /books unknown word cache
            localStorage.removeItem('all-unknown')
        })
    },
    handleRead(item) {
        if (item.opened) return false
        this.invalidateHeartbeat()
        return UseAPI('/update/mail', { method: "POST", queryParameters: `mailId=${item.id}` })
        .then((res) => {
            item.opened = true
        })
        .catch((dat) => {
            alert('error modifying data\ncheck console for logs')
            HandleRequestFail(dat)
            console.log('error log')
            console.log(JSON.stringify(dat))
        })
    },
    handleDelete(item, index) {
        // delete from UI
        if (!window.confirm('Would you like to delete this message? This action is irreversible.')) return false
        this.mail.splice(index, 1)
        this.invalidateHeartbeat()
        // send request
        // mailId = id
        if (item.permanent) { return false }
        if (!('id' in item)) { return false }
        return UseAPI('/delete/mail', { method: "DELETE", queryParameters: `mailId=${item.id}` })
        .catch((dat) => {
            alert('error modifying data\ncheck console for logs')
            HandleRequestFail(dat)
            console.log('error log')
            console.log(JSON.stringify(dat))
        })
    },
    setActiveMsg(id) {
        if (this.mail.find((i) => i.id === id)) this.flags.activeMsg = id
        return this.flags.activeMsg
    },
    getHeartbeat() {
        //  may want to turn this to a promise with a way to reject
        return UseAPI('/get/heartbeat', { method: "POST", blocking: true })
        .then((dat) => {
            ConsoleStyles.apiCallResult('heartbeat', dat)
            sessionStorage.setItem('lastHeartbeat', dat)
            return dat
        })
        .catch((dat) => {
            console.log('Your token is expired')
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(false)
        })
    },
    updateHeartbeat(lastHeartbeatInfo) {
        console.log('%crunning update heartbeat', window.ConsoleStyles.routine, lastHeartbeatInfo)
        if (!lastHeartbeatInfo) return false
        if ('items' in lastHeartbeatInfo) this.mail = lastHeartbeatInfo.items
        if ('modules' in lastHeartbeatInfo) {
            const {
                reviews,
                lessons,
                username,
                xp,
                xpToday,
                name
            } = lastHeartbeatInfo.modules
            if (reviews || reviews === 0) this.reviews = reviews
            if (lessons || lessons === 0) this.lessons = lessons
            if (username) this.name = username
            this.xp = xp || 0
            this.xpToday = xpToday || 0
            this.loggedIn = true
        }
        return true
    },
    invalidateHeartbeat() {
        console.log('%crunning invalidate hearbeat', window.ConsoleStyles.routineConsequence)
        sessionStorage.removeItem('lastHeartbeat')
    },
    sendReport() {
        const msg = this.composition
        this.flags.composing = false
        // send report here
        return msg
    },
    logout() {
        if (confirm('Sign out?')) {
            window.localStorage.removeItem('token')
            ApiPreloader.clearApiData()
            SendUserAlert('Signed out', 'user-info')
            return Promise.resolve()
        }
        return Promise.reject()
    }
}
