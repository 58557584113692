import Vue from 'vue';
import VueRouter from 'vue-router';
import { Modal as Bmodal, Dropdown } from 'bootstrap'
import PortalVue from 'portal-vue'
import Heading from '@/components/Heading/Heading.vue'

Vue.use(VueRouter);
Vue.use(PortalVue)

const generic = {
    desc: {
        name: 'description',
        content: 'Learn Japanese from native materials using Koohi\'s built in spaced repetition system.'
    },
}

const openGraph = {
    ogTitle: {
        name: 'og:title',
        content: 'Koohi.cafe - An SRS for Japanese Vocabulary'
    },
    ogDesc: {
        name: 'og:description',
        content: 'Learn Japanese from native materials using Koohi\'s built in spaced repetition system.'
    },
    ogType: {
        name: 'og:type',
        content: 'website'
    }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
        heading: Heading,
        default: () => import('../views/Generic.vue'),
    },
    children: [
        {
            path: '/',
            component: () => import('../components/Blog/Blog.vue'),
        },
        {
            name: 'Library',
            path: '/books',
            component: () => import('@/components/Books/Books.vue'),
            meta: {
                title: 'Library',
            }
        },
        {
            path: '/list',
            name: 'Vocabulary',
            component: () => import('@/components/Vocabulary/Vocabulary.vue'),
            meta: {
                title: 'Generate List',
            }
        },
        {
            path: '/lessons',
            name: 'Lessons',
            component: () => import('@/components/Lessons/Lessons.vue'),
            meta: {
                title: 'Manage Words',
            }
        },
        {
            path: '/data',
            name: 'Data',
            component: () => import('@/components/Data/Data.vue'),
            meta: {
                title: 'Statistics',
            }
        },
        {
            path: '/review',
            name: 'Review',
            component: () => import('@/components/Reviews/Reviews.vue'),
            meta: {
                title: 'Review',
            }
        },
        {
            path: '/importer',
            name: 'Importer',
            component: () => import('@/components/ImpExp/Importer/Import.vue'),
            meta: {
                title: 'Import Data',
            }
        },
        {
            path: '/exporter',
            name: 'Exporter',
            component: () => import('@/components/ImpExp/Exporter/Exporter.vue'),
            meta: {
                title: 'Export Data',
            }
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: () => import('@/components/Singles/Welcome.vue'),
            meta: {
                title: 'Koohi - Features',
            }
        },
        {
            path: '/policy',
            name: 'Policy',
            component: () => import('@/components/Singles/Policy.vue'),
            meta: {
                title: 'Private Policy',
            }
        },
        {
            path: '/reader',
            name: 'Reader',
            component: () => import('@/components/Reader/Reader.vue'),
            meta: {
                title: 'Reader',
            }
        },
        {
            path: '/parser',
            name: 'Parser',
            component: () => import('@/components/Parser/Parser.vue'),
            meta: {
                title: 'Parser',
            },
        },
    ],
    meta: {
        title: 'Koohi',
        metaTags: [
            generic.desc,
            openGraph.ogTitle,
            openGraph.ogDesc,
            openGraph.ogType,
        ]
    }
  },
];

const router = new VueRouter({
    routes,
});

// This callback runs before every route change, including on page load.
//  sauce: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })

    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

    return next();
});

/*
router.beforeEach((to, from, next) => {
    document.querySelectorAll('.modal-backdrop')
    .forEach((node) => node.remove())
    next()
})
*/

router.beforeEach((to, from, next) => {
    document.querySelectorAll('.modal')
    .forEach((node) => {
        const modal = Bmodal.getInstance(node)
        try {
            modal.hide()
        } catch (e) {
            //  nothing
        }
    })
    document.querySelectorAll('.dropdown')
    .forEach((node) => {
        const dropdown = Dropdown.getInstance(node)
        try {
            dropdown.hide()
        } catch (e) {
            //  nothing
        }
    })
    next()
})

export default router;
