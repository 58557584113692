//  worth looking into: getHeartbeat() askForTransfer()

function checkToken() {
    const token = localStorage.getItem('token')
    const refresh = localStorage.getItem('refreshToken')
    if (token) {
        return { access: `bearer ${token}`, refresh: `bearer ${refresh}` }
    }
    return false
}

function checkLoc() {
    //  check if local host
    if (localStorage.getItem('forceLive') !== null) return false
    if (window.location.origin.search('localhost') === -1) return false
    return true
}

function genUrl(location = null) {
    if (!location) return false
    const api = checkLoc() ? `http://localhost:3002/${location}` : `https://corpus.koohi.cafe/${location}`
    return api
}

export function SendUserAlert(msg = null, typ = 'alert-success') {
    if (!window.msgCnt) window.msgCnt = []
    if (!msg) { return false }
    const allowedTyp = ['alert-info', 'alert-success', 'alert-warning', 'alert-secondary', 'alert-primary', 'alert-danger', 'alert-light', 'alert-dark']
    window.msgCnt.push({ id: window.msgCnt.length, closed: false })
    const html = `
        <div msg=${window.msgCnt[window.msgCnt.length - 1].id} class="alert ${allowedTyp.includes(typ) ? typ : 'alert-success'} alert-dismissible fade show mx-auto anim-fade-in" role="alert">
          ${msg}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    `
    /* use a template to attach a bunch of html at once (seems template is cleaned when all the child nodes disappear (so no need to clean template specifically)) */
    const template = document.createElement('template')
    template.innerHTML = html.trim()
    document.getElementById('global-alert').appendChild(template.content.firstChild)

    const msgLen = msg.split(' ').length * 0.5 * 100//   assuming wpm of 120 per minute, 2 per second
    const closeTime = 2000 + msgLen

    return window.setTimeout(() => {
        if (window.msgCnt.length === 0) return false
        const found = window.msgCnt.find((i) => !i.closed)
        if (!found) return false

        found.closed = true
        // animate closing
        const el = document.querySelector(`[msg="${found.id}"]`)
        el.style.transition = 1000
        el.style.opacity = 0
        window.setTimeout(() => {
            el.remove()
        }, 1000)
        return true
    }, closeTime)
}

function recordAPICallLog(obj) {
    if (!window.localStorage.getItem('apiDebug')) return
    //  get storage item
    const apiLogs = JSON.parse(window.localStorage.getItem('apiDebugLogs') ?? "[]")
    //  record time
    apiLogs.push(obj)
}

function startAPICallLog(path) {
    return {
        path,
        timers: {
            start: 0,
            end: 0,
            timeDifference: "",
        },
        startTimer() {
            this.timers.start = new Date().getTime()
            return this
        },
        stopTimer() {
            this.timers.end = new Date().getTime()
            this.timers.timeDifference = this.calculateTimeDifference()
            return this
        },
        calculateTimeDifference() {
            const timeDifference = this.timers.end - this.timers.start
            return `${(timeDifference / 1000).toFixed(2)}s`
        },
        recordLog() {
            if (!window.localStorage.getItem('apiDebug')) return
            const apiLogs = JSON.parse(window.localStorage.getItem('apiDebugLogs') ?? "[]")
            //  record time
            const { start, end, timeDifference } = this.timers
            const obj = {
                path, start, end, timeDifference
            }
            apiLogs.push(obj)
            console.warn(`API Call ${obj.path} took ${timeDifference}`)
            window.localStorage.setItem('apiDebugLogs', JSON.stringify(apiLogs))
        }
    }
}

export async function UseAPI(endpoint,
    {
        method = "GET",
        body = null,
        queryParameters = null,
        api = "api",
        auth = true,
        blocking = false,
    } = {}) {
    let root
    if (window.awaitingKoohiAPI) {
        // so this will stop multiple requests from happening at the same time...
        // preventing multiple tokens from being refreshed and created
        // but its also asynchronous so its incredibly inelegant
        // and could cause hangups
        await window.awaitingKoohiAPI
            .catch((res) => {
            })
            .then((res) => {
                //  console.log('request cleared')
                window.awaitingKoohiAPI = null
                return res
            })
    }
    if (checkLoc()) {
        root = api === 'corpus' ? 'http://localhost:3002' : 'http://localhost:3001'
    } else {
        root = api === 'corpus' ? 'https://corpus.koohi.cafe' : 'https://api.koohi.cafe'
    }
    const final = `${root}${endpoint}${queryParameters ? `?${queryParameters}` : ''}`
    const config = {
        method,
        headers: {
            "Content-Type": "application/json",
        },
    }
    if (body) config.body = body
    if (auth) {
        const token = checkToken()
        config.headers.Authorization = token.access
        config.headers.Refresh_Token = token.refresh
    }
    //  start timer
    const apiLog = startAPICallLog(endpoint).startTimer()
    const prom = new Promise(
        (resolve, reject) => fetch(final, config)
            .then((res) => {
                apiLog.stopTimer().recordLog()
                if (!res.ok) throw res
                return res.json()
            })
            .then((dat) => {
                if ('err' in dat && dat.err && dat.err !== []) throw dat
                // check for tokens
                if (typeof dat === 'object') {
                    // looking for responses of the format
                    // {items: [], (optional) modules: [], (optional), err}
                    if ('modules' in dat) {
                        if ('tokens' in dat.modules) {
                            localStorage.setItem('token', dat.modules.tokens.token)
                            localStorage.setItem('refreshToken', dat.modules.tokens.refreshToken)
                            console.log('set new tokens')
                        }
                    }
                    if ('err' in dat && dat.err) {
                        console.log('Got err value from request', dat.err)
                    }
                }
                /* return resolve(dat) */
                const debugDelay = 0
                if (debugDelay > 0) {
                    const x = () => resolve(dat)
                    console.warn('%cTheres a delay active on the api', window.ConsoleStyles.info, debugDelay)
                    return setTimeout(x, 5000)
                }
                return resolve(dat)
            })
            .catch((res) => {
                console.warn('Api received a rejection (failure)', res)
                reject(res)
            })
    )
    if (blocking) window.awaitingKoohiAPI = prom
    return prom
}

export async function HandleRequestFail(dat, failureOkay = false) {
    console.log('Look at request failure...', dat)
    if (!('status' in dat)) {
        console.log(dat)
    }
    if (dat.status === 403) {
        if (!failureOkay) {
            document.addEventListener("DOMContentLoaded", () => {
                if (window.modals) window.modals.login.show()
            })
            SendUserAlert('Not logged in', 'alert-warning')
        } else {
            console.log('Non-fatal, but user is not currently logged in')
        }
    }
    return false
}
function setColorTheme(node = null, debug) {
    const dark = localStorage.getItem('dark')
    if (!node) {
        // assume body
        if (dark) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
        return
    }
    try {
        if (!node) return
        document.addEventListener("DOMContentLoaded", () => {
            if (localStorage.getItem('dark')) {
                node.classList.add('dark')
            } else {
                node.classList.remove('dark')
            }
        })
    } catch (e) {
        console.log(e)
    }
}

/**
 * A promise-based timeout
 */
export function promiseDelay(delay, carryoverData) {
    return new Promise((resolve) => {
        window.setTimeout(() => {
            if (carryoverData) return resolve(carryoverData)
            return resolve()
        }, delay)
    })
}

export default {
    checkToken,
    checkLoc,
    genUrl,
    SendUserAlert,
    UseAPI,
    HandleRequestFail,
    setColorTheme,
    promiseDelay
}
