/* eslint-disable consistent-return */
/*
Flow Chart
Preload:
1. Hover over a link item
2. use apiDataExists to check if item needs to be preloaded
3. if apiDataExists = false, addToQueue

On Relevant Page:
1. Check if preload data existed at anypoint (ApiPreloader.apiDataExists(key))
2. Check if preload data is finished (ApiPreloader.awaitApiCallFinish.then((result) => something))
1.B If preload data never existed, run original command
*/
class ApiPreloader {
    //  return false or true (if result.type === 'pending') or an apiData item
    static apiDataExists(key, debug = true) {
        //  check if apiPreload data exists
        const allData = ApiPreloader.getApiData()
        if (!allData) {
            if (debug) console.warn('Preloader hasnt preloaded anything yet', key)
            return false
        }
        //  check if item exists in allData
        if (!(key in allData)) {
            if (debug) console.warn('Preloader hasnt save a key for this', key)
            return false
        }
        //  check if item has expired
        if (allData[key].expiration < new Date().getTime()) {
            if (debug) console.warn('Preloader had a key but its expired', key)
            return false
        }
        if (allData[key].result.type === 'pending') {
            if (debug) console.warn('Preloader has a key but it isnt finished loading', key)
            return true
        } // solution pending
        if (debug) console.warn('Preloader has a key', key, allData[key])
        return allData[key]
    }

    //  preload an item
    static queueApiCall({ key, func, expiration = 60000 } = {}, debug = false) {
        const createAndReturnAllData = () => {
            ApiPreloader.saveApiData({})
            return {}
        }
        const allData = ApiPreloader.getApiData() || createAndReturnAllData()

        const apiCall = func()
                        .then((result) => {
                            if (!result) return Promise.reject()
                            if (debug) console.warn('ApiPreloader wrapper promise', 'resolved')
                            ApiPreloader.addApiCallResult(key, {
                                type: 'resolve', value: result
                            })
                            return result
                        })
                        .catch((result) => {
                            if (debug) console.warn('ApiPreloader wrapper promise', 'rejected')
                            ApiPreloader.addApiCallResult(key, {
                                type: 'reject', value: result
                            })
                            return result
                        })
        allData[key] = {
            value: apiCall,
            expiration: new Date().getTime() + expiration,
            result: {
                type: 'pending',
                value: null
            }
        }
        ApiPreloader.saveApiData(allData, debug)
        return apiCall
    }

    //  save an item (for when you didnt preload but you can still save an api call)
    static saveStandardApiCall({ key, value, expiration = 60000 } = {}, debug = false) {
        if (debug) console.log('%cTrying to save API Call', window.ConsoleStyles.info, key, value)
        const createAndReturnAllData = () => {
            ApiPreloader.saveApiData({})
            return {}
        }
        const allData = ApiPreloader.getApiData() || createAndReturnAllData()
        allData[key] = {
            value: null,
            expiration: new Date().getTime() + expiration,
            result: {
                type: 'resolve',
                value
            }
        }
        if (debug) console.warn('Saving standard api call results', value)
        ApiPreloader.saveApiData(allData, debug)
        return value
    }

    static addApiCallResult(key, value) {
        const allData = ApiPreloader.getApiData()
        allData[key].result = value
        ApiPreloader.saveApiData(allData)
    }

    //  save all data
    static saveApiData(value, debug) {
        if (debug) console.warn('saving preloader data', value)
        window.sessionStorage.setItem('apiPreload', JSON.stringify(value))
    }

    //  get all data
    static getApiData() {
        const data = window.sessionStorage.getItem('apiPreload')
        if (!data) return false
        return JSON.parse(data)
    }

    static clearApiData(debug = false) {
        const data = window.sessionStorage.removeItem('apiPreload')
        if (debug) window.console.log('Preload data cleared')
    }

    static clearApiKey(key, debug = false) {
        const data = ApiPreloader.getApiData()
        if (!data) return
        if (data[key]) delete data[key]
        if (debug) console.warn('preloader deleting key', key)
        ApiPreloader.saveApiData(data, debug)
    }

    //  check on a value repeatedly to see if the pending api call has finished
    static awaitApiCallFinish(key, debug = true) {
        return new Promise((resolve, reject) => {
            const firstCheck = ApiPreloader.apiDataExists(key, debug)
            // eslint-disable-next-line prefer-promise-reject-errors
            if (firstCheck === false) return reject('nokey')
            if (firstCheck && firstCheck !== true) return resolve(firstCheck?.result?.value)
            const interval = 1000 / 10
            const maxTime = 1000 // 15s
            let currentIteration = 0
            const funcResolve = (keyObj) => {
                console.log('%cExpecting API info to display', window.ConsoleStyles.debug)
                clearInterval(window[key])
                return keyObj.result.value
            }
            const funcReject = (keyObj) => {
                console.log('%cAPI preloader API failed', window.ConsoleStyles.debug)
                clearInterval(window[key])
                ApiPreloader.clearApiKey(key, true)
                return keyObj.result.value
            }
            const funcTimedOut = (msg) => {
                console.log('%cAPI preloader timed out', window.ConsoleStyles.debug)
                console.log('%cAPI returning a rejection', window.ConsoleStyles.debug)
                clearInterval(window[key])
                ApiPreloader.clearApiKey(key, true)
                return msg
            }
            const func = () => {
                console.log('%cChecking API call status', window.ConsoleStyles.info)
                const keyObj = ApiPreloader.apiDataExists(key)
                const promiseStatus = keyObj?.result?.type
                switch (promiseStatus) {
                    case 'resolve':
                        resolve(funcResolve(keyObj))
                        break;
                    case 'reject':
                        reject(funcReject(keyObj))
                        break;
                    case undefined:
                    case 'pending':
                        break;
                    default:
                        break;
                }
                if (debug) console.log('awaitApiCallFinish', promiseStatus)
                if (currentIteration === maxTime) {
                    return reject(funcTimedOut('timeout'))
                }
                currentIteration += 1
                return false
            }
            func()
            window[key] = setInterval(func, interval)
        })
    }
}

export default ApiPreloader
