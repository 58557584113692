// sessionStorage
export const SS = {
    AWAITING_FIRST_HEARTBEAT: 'awaitingFirstHeartbeat', // boolean
    PUBLIC_API: 'publicApi', // boolean
    USER_SETTINGS_COOKIE: 'userSettingsCookie',
    LAST_HEARTBEAT: 'lastHeartbeat',
    UNKNOWN_WORD_STORAGE: 'all-unknown',
    get(key) {
        // lol
        window.SS = this
        const value = window.sessionStorage.getItem(key)
        try {
            return JSON.parse(value)
        } catch (e) {
            return value
        }
    },
    set(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    remove(key) {
        window.sessionStorage.removeItem(key)
    }
}

export const LS = {
    DARK: 'dark', // boolean
    TOKEN: 'token', // string
    get(key) {
        const value = window.localStorage.getItem(key)
        try {
            return JSON.parse(value)
        } catch (e) {
            return value
        }
    },
    set(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    },
    remove(key) {
        window.localStorage.removeItem(key)
    }
}
