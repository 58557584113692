<template>
    <div class="modal" id="settingsModal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <!--sections-->
                <div class="modal-header" style="font-size:1.5rem; justify-content:flex-start; place-items: stretch;">
                    <span class="badge" :class="flags.active == 'about' ? 'bg-info' : 'bg-secondary'"
                        @click="() => { this.flags.active = 'about' }">about
                        <span class="fa-stack marg-small">
                            <i class="fas fa-question fa-stack-2x"></i>
                        </span>
                    </span>
                    <span v-if="localStorage.getItem('token') && flags.enabled.includes('mail')" class="badge"
                        :class="flags.active == 'messages' ? 'bg-info' : 'bg-secondary'"
                        @click="() => { this.flags.active = 'messages' }">mail
                        <span class="fa-stack marg-small">
                            <i class="fas fa-envelope fa-stack-2x"></i>
                            <i v-if="mail.some(i => !i.opened)" class="fas fa-circle c-red notification-circle">
                                <div class="notification-number" style="color:white!important">
                                    {{ mail.filter(i => !i.opened).length }}</div>
                            </i>
                        </span>
                    </span>
                    <span v-if="localStorage.getItem('token') && flags.enabled.includes('trimming')" class="badge"
                        :class="flags.active == 'trimming' ? 'bg-info' : 'bg-secondary'"
                        @click="() => { this.flags.active = 'trimming'; if (this.trimming.length == 0) this.getTrimming() }">trim
                        <span class="fa-stack marg-small">
                            <i class="fas fa-cut fa-stack-2x"></i>
                        </span>
                    </span>
                </div>
                <div class="modal-body container-fluid">
                    <!--settings-->
                    <!--for bug reporting, etc.-->
                    <div v-if="flags.composing" id="bug_report" class="row">
                        <div class="col-10 mx-auto">
                            <h2>Send a bug report</h2>
                            <textarea v-model="composition" class="w-100"></textarea>
                            <button type="button" class="btn bg-primary" @click="sendReport()">Send</button>
                        </div>
                    </div>
                    <!--EOR-->
                    <!--mail-->
                    <div v-show="flags.active == 'messages'" id="mail-system">
                        <Mail class="w-100" :mail="mail" :flags="flags"
                            v-on:message-clicked="[handleRead($event[0]), setActiveMsg($event[1])]"
                            v-on:message-deleted="handleDelete($event[0], $event[1])" />
                    </div>
                    <!--trimming-->
                    <div v-show="flags.active == 'trimming'" class="row" id="mail-system">
                        <div v-if="trimming && trimming.length > 0" class="col-12 align-center">
                            <div class="col-12">
                                If you'd like to clear your account (for a fresh transfer or something) then run all three
                                of these commands, one after another.
                            </div>
                        </div>
                        <div v-else class="col-12 align-center">
                            <div class="col-12">
                                No items found on account.
                            </div>
                        </div>
                        <div v-if="trimming && trimming.length !== 0" class="col-12 align-center">
                            <div class="col-12 marg-big">
                                <div v-if="trimming.filter(i => i.queue_state == 0).length == 1">
                                    <h2>Clear all lessons</h2>
                                    <p>Clears all active lessons</p>
                                    <p>Affects {{ trimming.filter(i => i.queue_state == 0)[0].cnt }} items</p>
                                    <button class="btn bg-primary" @click="handleTrimming('lessons')">Clear Items</button>
                                </div>
                            </div>
                            <div class="col-12 marg-big">
                                <div>
                                    <h2>Clear low-level active items</h2>
                                    <p>Delete all active cards leveled 1-6</p>
                                    <p>Affects {{ trimming.filter(i => i.queue_state >= 1 && 6 >=
                                        i.queue_sate).map(i => i.cnt).reduce((a, b) => a + b, 0) }} items</p>
                                    <button class="btn bg-primary" @click="handleTrimming('active-low')">Clear
                                        Items</button>
                                </div>
                            </div>
                            <div class="col-12 marg-big">
                                <div>
                                    <h2>Clear all active items</h2>
                                    <p>Delete active cards leveled 1-6 and trash active cards leveled 7-9</p>
                                    <p>Affects {{ trimming.filter(i => i.queue_state >= 1 && 9 >= i.queue_state
                                    ).map(i => i.cnt).reduce((a, b) => a + b, 0) }} items</p>
                                    <button class="btn bg-primary" @click="handleTrimming('active')">Clear Items</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="flags.active == 'about'" class="row" id="mail-system">
                        <div class="col-12 align-center">
                            <h2 class="marg-big">Koohī</h2>
                            <div>
                                Site for vocabulary practice based on native materials.
                                <br />
                                This site uses <a underline
                                    href="http://www.edrdg.org/wiki/index.php/JMdict-EDICT_Dictionary_Project">JMdict</a>
                                and <a underline href="http://www.edrdg.org/wiki/index.php/KANJIDIC_Project">KANJIDIC</a>
                                files and is done in comformance with its <a underline
                                    href="http://www.edrdg.org/edrdg/licence.html">license</a>.
                                <br />
                                All images and novels belong to their respective copyright holders. Data created from
                                sources are done from legally purchased copies or publicly available sources.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueTippy, { TippyComponent } from "vue-tippy";
import M from './methods'
import Mail from './Mail.vue'

const {
    getTrimming,
    handleTrimming,
    handleRead,
    handleDelete,
    setActiveMsg,
    sendReport, logout,
    invalidateHeartbeat
} = M

export default {
    name: 'Modal',
    components: {
        Mail
    },
    props: {
        name: {
            default: () => ""
        },
        mail: {
            default: () => []
        },
    },
    data() {
        return {
            flags: {
                active: 'about',
                activeMsg: null,
                composing: false,
                enabled: [
                    'mail', 'voting', 'profile', 'trimming', 'about'
                ],
            },
            trimming: [],
            localStorage: window.localStorage,
        }
    },
    methods: {
        getTrimming,
        handleTrimming,
        handleRead,
        handleDelete,
        setActiveMsg,
        sendReport,
        invalidateHeartbeat,
        runLogout() {
            logout()
                .then((res) => {
                    console.log('running post-logout')
                    const x = this.$parent
                    this.invalidateHeartbeat()
                    x.name = ""
                    x.reviews = 0
                    x.lessons = 0
                    x.mail = []
                })
                .then(() => {
                    document.location.href = '/'
                })
        },
    },
    created() {
        console.log('%cHeading Modal created', window.ConsoleStyles.createdComponent, this)
    }
}
</script>

<style lang="sass" scoped>
ul
    margin: 0
    list-style-type: none
    i
        color: white
    > li
        display: inline-block
        font-variant: small-caps
        padding: .25em
        font-weight: 100
h2
    font-weight: 200
    letter-spacing: .06em
    margin: 0
.modal-header
    i
        color: white
.modal-body
    min-height: 10rem
.break
    border-bottom: 12px solid #232323
    box-shadow: 0 2px 5px #232323
    margin-bottom: 2rem
li a, li i, .modal-body i, .logo a
    &:after
        display: block
        content: ''
        transition: transform 200ms ease-out
    &:hover, &[active]
        text-decoration: none
        color: rgba(0, 171, 255, 0.76)!important
#mail-system
    .fas.fa-times
        color: white
.notification-circle
    left: 75%
</style>
