import '@fortawesome/fontawesome-free/css/all.css';
import 'lockr'
//  import 'bootstrap/dist/css/bootstrap.min.css'
//  import 'jquery/src/jquery'
//  import 'bootstrap/dist/js/bootstrap.min'
import Vue from 'vue'
import VueMq from 'vue-mq'
import vClickOutside from 'v-click-outside'
import ConsoleStyles from '@/assets/consoleStyles'
import App from './App.vue'
import router from './router'

require('@/assets/css/main.sass')

window.ConsoleStyles = ConsoleStyles
Vue.config.productionTip = false
Vue.use(VueMq, {
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400
    },
    defaultBreakpoint: 'sm'
})
Vue.use(vClickOutside)

window.v = new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app')
